html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.container {
  /* commented out for login page */
  /*margin: 66px auto 0 auto;*/
  /* max-width: 1500px; */
  text-align: center;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vh;
  z-index: 40;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
}

.popup_inner {
  position: fixed;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
  border-radius: 20px;
  background: rgb(56, 55, 55);
  color: black;
}

.loading_popup {
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vh;
  z-index: 40;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.85);
  overflow: hidden;
}

.loading_popup_inner {
  position: fixed;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
  border-radius: 20px;
  color: black;
}
.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #fff;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.button_exit {
  position: absolute;
  right: 0;
  margin: 10px;
  cursor: pointer;
}

.button_skip {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 10px;
}

.button_next {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 10px;
}

.row {
  display: flex;
}

.category_one {
  position: absolute;
  left: 10px;
  bottom: 45px;
  font-size: 15;
  max-width: 50%;
}

.category_two {
  position: absolute;
  right: 10px;
  bottom: 45px;
  font-size: 15;
  max-width: 50%;
}

/* Create three equal columns that sits next to each other */
.column {
  width: 50%;
  height: 70%;
  border-radius: 20px;
  padding: 1%;
  cursor: pointer;
  margin: auto;
  position: absolute;
}
img {
  height: 100%;
}

.column:hover {
  width: 50%;
  height: 70%;
  border-radius: 20px;
  padding: 1%;
  cursor: pointer;
  box-shadow: 0 0 0 5px #fcecdc;
  margin: auto;
  position: "absolute";
}

.columnActive {
  width: 50%;
  height: 70%;
  border-radius: 20px;
  padding: 1%;
  cursor: pointer;
  box-shadow: 0 0 0 5px #7688d4;
  margin: auto;
  position: "absolute";
}

.column_iphone {
  /*this is for iphone size*/
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 1%;
  cursor: pointer;
  position: relative;
}
img {
  height: 100%;
}

.column_iphone:hover {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 1%;
  cursor: pointer;
  box-shadow: 0 0 5px #fcecdc;
}

.email_verify {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  color: white;
}

/* CUSTOM SCROLL BAR */

.scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.convoChat {
  /* position: relative; */
}
.convoChat:hover {
  /* opacity: 0; */
  color: white;
  background-color: #555;
}
.convoChat:hover .convoChat__hover {
  opacity: 1;
  float: right;
}
.convoChat__hover {
  /* position: absolute; */
  top: 0;
  opacity: 0;
  display: block;
  height: 0;
}
.convoChat__no-hover {
  opacity: 1;
}
